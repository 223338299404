import React from 'react'
import MUIButton from '@material-ui/core/Button'

interface IProps {
  color?: 'primary' | 'secondary'
  onClick?: () => void
  isMobile?: boolean
  disabled?: boolean
  href?: string
  width?: string | number
}

class Button extends React.Component<IProps> {
  render() {
    return (
      <MUIButton
        onClick={this.props.onClick}
        variant="contained"
        color={this.props.color}
        style={{
          width: this.props.isMobile
            ? undefined
            : this.props.width
            ? this.props.width
            : 150,
        }}
        disabled={this.props.disabled}
        href={this.props.href}
      >
        {this.props.children}
      </MUIButton>
    )
  }
}

export default Button
