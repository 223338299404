/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { muiTheme } from '../ux/roviTheme'
import Header from './header'
import './styles-jsComponents/layout.css'

const Layout = ({ loading, theEnd, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MuiThemeProvider theme={createMuiTheme(muiTheme)}>
      <CssBaseline />
      <div
        style={{
          backgroundImage:
            'url(https://rovitracker.com/wp-content/uploads/2019/01/Orange-1980.png)',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          loading={loading}
          theEnd={theEnd}
        />
        <div
          style={{
            margin: `0 auto`,
            marginTop: 50,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <main>{children}</main>
          <footer
            style={{
              marginTop: `2rem`,
            }}
          ></footer>
        </div>
      </div>
    </MuiThemeProvider>
  )
}

Layout.propTypes = {
  loading: PropTypes.bool,
  theEnd: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Layout
