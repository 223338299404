import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import loadable from '@loadable/component'
import Button from '../Button'

const WidthViewport = loadable(() => import('../ux/WidthViewport'))

const Header = ({ loading, theEnd, siteTitle }) => (
  <WidthViewport>
    {({ isMobile }) => (
      <header
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: isMobile ? 300 : 1200,
            height: isMobile ? 120 : 90,
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMobile ? 'center' : 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <h1 style={{ margin: 0, marginTop: 5 }}>
            <Link
              to="https://rovitracker.com/"
              style={{
                textDecoration: `none`,
              }}
            >
              <img
                src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-120x78.png"
                alt="Rovitracker Logo"
              />
            </Link>
          </h1>
          {theEnd ? (
            <div style={{ marginRight: 15 }}>
              {loading ? null : (
                <Button
                  color="primary"
                  href="https://app.rovitracker.com/sign-up.html"
                  width={isMobile ? 170 : 200}
                >
                  Sign Up For Free
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </header>
    )}
  </WidthViewport>
)

Header.propTypes = {
  loading: PropTypes.bool,
  theEnd: PropTypes.bool,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
